import React from "react";
import bck from "../assets/img/bck.png";

function BckHome() {
  return (
    <>
      <img src={bck} alt="" className="bck" />
    </>
  );
}

export default BckHome;
