import React from "react";
import bck from "../assets/img/bck.png";

function Bck() {
  return (
    <>
      <img src={bck} alt="" className="bckOther" />
    </>
  );
}

export default Bck;
