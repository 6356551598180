import React from "react";
import AffilateComponent from "../components/AffilateComponent";

function Affilate() {
  return (
    <>
      <AffilateComponent />
    </>
  );
}

export default Affilate;
