import React from "react";
import FaqComponent from "../components/FaqComponent";

function Faq() {
  return (
    <>
      <FaqComponent />
    </>
  );
}

export default Faq;
