import React from "react";
import SupportComponent from "../components/SupportComponent";

function Support() {
  return (
    <>
      <SupportComponent />
    </>
  );
}

export default Support;
