import React from "react";
import TermsComponent from "../components/TermsComponent";

function Terms() {
  return (
    <>
      <TermsComponent />
    </>
  );
}

export default Terms;
